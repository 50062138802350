.add_transform {}

.cp {
    cursor: pointer;
}

/* .css-1s2u09g-control {
    background-color: #464f5b !important;
    border-color: #464f5b !important;
    color: #f1f1f1 !important;
} */

/* .css-4ljt47-MenuList {
    background-color: #464f5b !important;
} */

/* .css-1s2u09g-control {
    background-color: #464f5b !important;
    color: white !important;
} */

::-webkit-scrollbar {
    display: none;
}

/* .css-1pahdxg-control {
    background-color: #464f5b !important;
    border: none !important;
    color: white !important;

} */

/* .css-1pndypt-Input {
    color: white !important;
} */

/* .css-qc6sy-singleValue {
    color: white !important;
}

.css-6j8wv5-Input {
    color: white !important;
} */

.css-1pahdxg-control {
    box-shadow: none !important;
}

.main_head i.mdi.mdi-dots-vertical {
    display: none !important;
}

.Active_data {
    /* background-color: white !important; */
    /* background-color: #7d7e83 !important; */
    background-color: #4d90fe !important;
    color: black !important;
}

.InActive_data {
    /* background-color: #464f5b !important; */
    /* background-color: #dedfe1 !important; */
    background-color: #b6c1d7 !important;
    color: black !important;

}

g.text-group {
    display: none;
}

.hover-effect:hover {
    /* Add your hover effect styles here */
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    transition: box-shadow 0.3s ease;
}

.custome-font {
    font-size: 13px !important;
}

.header-title {
    font-size: 13px !important;
}

.add_transform {
    top: 0px;
    left: 0px;
    right: 0px;
    transform: translate(10px, -35px);
}

.add_transform_button {
    top: 0px;
    left: 0px;
    right: 0px;
    transform:translate(-17px, -37px);
}



/* .goog-te-combo, .VIpgJd-ZVi9od-ORHb *, .VIpgJd-ZVi9od-SmfZ *, .VIpgJd-ZVi9od-xl07Ob *, .VIpgJd-ZVi9od-vH1Gmf *, .VIpgJd-ZVi9od-l9xktf * {
    display:none !important;
} */

.goog-te-banner-frame.skiptranslate {
    display: none !important;
}
.VIpgJd-ZVi9od-ORHb-OEVmcd {
    display: none !important;
}
.goog-te-gadget {
    color: #fff !important;
}
div#google_translate_element span {
    display: none !important;
}
.VIpgJd-ZVi9od-aZ2wEe-wOHMyf.VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc {
    display: none !important;
}
    .VIpgJd-ZVi9od-ORHb{
    display:none !important;
    }
div#goog-gt-tt {
    display: none !important;
}